<template>
  <!-- 卡密 - 卡密设置 -->
  <div class="pageContol listWrap setUp">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">卡密管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">卡密设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          ><el-form-item label="卡面使用规则" prop="useRule">
            <el-input
              type="textarea"
              v-model="ruleForm.useRule"
              maxlength="200"
              placeholder="请输入卡面使用规则"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item class="cz">
            <!-- <el-button
              class="bgc-bv"
              size="small"
              round
              @click="$router.back(-1)"
              >取消</el-button
            > -->
            <el-button
              class="bgc-bv"
              size="small"
              round
              type="primary"
              @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
          <el-popover placement="right-start" trigger="hover" v-model="visible">
            <img src="../../assets/kmbjgt.png" alt="" style="width:400px;" />
            <el-button type="text" slot="reference" class="cksl"
              >查看示例</el-button
            >
          </el-popover>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "setUp",
  data() {
    return {
      // 卡密数据
      ruleForm: {
        useRule: "", // 卡面使用规则
      },
    };
  },
  watch: {},
  methods: {
    // 确定
    submitForm(formName) {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       this.dialogVisible1 = true;
      //     }
      //   });
      this.$post(
        "/biz/card/setting/update",
        {
          useRule: this.ruleForm.useRule.replace( /\n/g, "sunrealbr"),
        },
        3000,
        false,
        2
      )
        .then((res) => {
          this.$message.success("设置成功!");
        })
        .catch(() => {});
    },
    // 获取
    getInfo() {
      this.$post("/biz/card/setting/get", {}, 3000, false, 2)
        .then((res) => {
          this.ruleForm.useRule = res.data.useRule.replace(/sunrealbr/g, "\n");
        })
        .catch(() => {});
    },
  },
  computed: {},
  mounted() {},
  created() {
    this.getInfo();
  },
};
</script>
  
 <style lang="less" scoped>
.setUp {
  .framePage {
    .framePage-body {
      padding: 60px;
      .el-form {
        width: 700px;
        position: relative;
        .cksl {
          position: absolute;
          top: 0;
          right: -70px;
          color: #4089fa;
          cursor: pointer;
        }
        .cz {
          text-align: center;
          margin-top: 20%;
          .el-button {
            padding: 9px 25px;
          }
        }
      }
    }
  }
}
.setUp
  .framePage
  .framePage-body
  .el-form
  .el-textarea
  /deep/
  .el-textarea__inner {
  height: 170px;
}
.el-popover {
   box-shadow: none!important;
   border: 0!important;
}
.el-popover .el-time-panel {
  box-shadow: none!important;
}
</style>