import { render, staticRenderFns } from "./setUp.vue?vue&type=template&id=904b0318&scoped=true"
import script from "./setUp.vue?vue&type=script&lang=js"
export * from "./setUp.vue?vue&type=script&lang=js"
import style0 from "./setUp.vue?vue&type=style&index=0&id=904b0318&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "904b0318",
  null
  
)

export default component.exports